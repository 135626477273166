/* 
    CSS Keyframe Animation for the Biker SVG
    Scott
    2022
*/
#right-wheel,
#left-wheel {
  animation: wheel 2s infinite linear;
  transform-origin: center;
  transform-box: fill-box;
}

#man-bike {
  animation: bike 1s ease-in-out infinite alternate;
  transform-origin: bottom;
}

#hat {
  animation: hat 1s ease-in-out infinite alternate;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes wheel {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

@keyframes bike {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(20deg);
  }
}

@keyframes hat {
  from {
    transform: translateY(20%) rotateZ(0deg);
  }
  to {
    transform: translateY(50%) rotateZ(10deg);
  }
}
